import { createSlice } from '@reduxjs/toolkit';
import { getEmployerProfile, isAdminDetails, patchBasicProfile } from '../../api/Employer/employer';

const initialState = {
    first_name: "",
    last_name: "",
    designation: "",
    phone: "",
    email: "",
    profile_pic_path: "",
    company:"",
    logo:"",
    teams_id:"",
    isAdmin:"",
    status:"",
    is_international: false,
    employer_id : "",
    is_team_active: true,
  };

  const employerProfileSlice = createSlice({
    name: 'employerProfileData',
    initialState,
    reducers: {
      setProfileData: (state, action) => {
        state.first_name = action.payload.first_name;
        state.last_name = action.payload.last_name;
        state.designation = action.payload.designation;
        state.phoneNumber = action.payload.phone;
        state.email = action.payload.email;
        state.profile_pic_path = action.payload.profile_pic_path;
        state.company = action.payload.company;
        state.logo = action.payload.logo;
        state.teams_id = action.payload.teams_id;
        state.isAdmin = action.payload.is_admin;
        state.status = action.payload.status;
        state.is_international = action.payload.is_international;
        state.employer_id = action.payload.id
        state.is_team_active = action.payload.is_team_active
        
      },
    },
  });

  export const {  setProfileData } = employerProfileSlice.actions;


  export const fetchProfileData = (token) => async (dispatch) => {
    // console.log('Employer profile data : ',profileData)
    try {
      const resData = await getEmployerProfile(token);
      const response = await isAdminDetails(token,resData?.data?.data?.teams_id)
      console.log('xde,ki', response?.data?.is_admin);
      if(resData?.data?.msg =="success"){
        const profileData = {
          ...resData?.data?.data,
          is_admin: response?.data?.is_admin, // Add is_admin to the object
          status: response?.data?.status,
          is_team_active: response?.data?.is_team_active
        };
        console.log(' this is my profileData sjfklgjs', profileData);
        
        // Dispatch the updated object
        dispatch(setProfileData(profileData));
        
        return profileData;
    }
    } catch (error) {
      // Handle API call errors
      // dispatch(setError('Error Fetching Employer Profile Data'));
      console.log("Error Fetching Employer Profile Data")
      return error;
    }
  };

  export const updateProfileData = (profileData, token) => async (dispatch) => {
    console.log('Employer profile data : ',profileData)
    try {
      const resData = await patchBasicProfile(profileData, token);
      if(resData.data.msg === "success"){
        dispatch(fetchProfileData(token))
      }
    } catch (error) {
      // Handle API call errors
      console.log("Error Updating Employer Profile Data")
      return error;
    }
  };



export default employerProfileSlice.reducer;