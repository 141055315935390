import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  redirectToApply: false, 
  jobId: null,          
};

const applicationRedirectSlice = createSlice({
  name: 'applicationRedirect',
  initialState,
  reducers: {
    setRedirectInfo: (state, action) => {
      state.redirectToApply = true;
      state.jobId = action.payload; 
    },
    clearRedirectInfo: (state) => {
      state.redirectToApply = false;
      state.jobId = null;
    },
  },
});

export const { setRedirectInfo, clearRedirectInfo } = applicationRedirectSlice.actions;
export const selectRedirectInfo = (state) => state.applicationRedirect.applicationRedirect;
export default applicationRedirectSlice.reducer;
